import React, { ReactNode } from 'react';
import clsx from 'clsx';

interface NavBarProps {
  className?: string;
  children?: ReactNode;
  leftClassName?: string;
  midClassName?: string;
  rightClassName?: string;
}

export const NavBar: React.FC<NavBarProps> = ({
  className = '',
  children,
  leftClassName = '',
  midClassName = '',
  rightClassName = '',
  ...rest
}) => {
  return (
    <nav
      data-testid='cypress-navBar'
      className='flex flex-col shadow-lg  top-0 shadow-black/5 sticky bg-white w-full'
    >
      <div className={`flex flex-1 relative `}>
        <div
          className={clsx(
            'left-container flex absolute left-2 top-0 bottom-0 items-center',
            leftClassName
          )}
          onClick={() => {
            alert('GGGG');
          }}
        >
          <span>Back</span>
        </div>
        <div
          className={clsx(
            'mid-container flex flex-1 px-12 py-3.5 items-center justify-center',
            midClassName
          )}
        >
          <span>港澳寶典2023</span>
        </div>
        <div
          className={clsx(
            'right-container flex absolute right-2 top-0 bottom-0 items-center',
            rightClassName
          )}
        >
          <span>GO</span>
        </div>
      </div>
      {children}
    </nav>
  );
};
