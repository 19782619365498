import React from 'react';

import {
  Cards,
  Container,
  Footer,
  Header,
  Main,
  NavBar,
  InputToolBar,
} from 'components';

import config from 'config';

const Home: React.FC = () => {
  return (
    <>
      <span>{config.Test}</span>
      <NavBar />
      <Cards />
      <Cards />
      <Cards />
      <Cards />
      <InputToolBar />
      {/* <Container>
      
        <Main />
        <Cards />
        <Footer />
      </Container> */}
    </>
  );
};

export default Home;
